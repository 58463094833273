import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql, Link } from "gatsby";

/**
 * Sign up Box Component.
 *
 * @param title - Title of the box. Defaults to "Join the associates program"
 * @param btnText - Text of button. Defaults to "Sign up"
 * @param link - link url to signup or similar.
 * @param benefit - Optional string with sign up benefit description.
 * @param variant - Display type. Can be big, small or bounty.
 *
 * TODO Link to Signup
 *
 * variant big = Rocket Icon, Title, button and benefit
 * variant bounty = Essentially the same as big but slightly different styling and icon
 * variant small = Only Title and button
 */
const JoinTheProgram = ({ title, btnText, link, benefit, variant }) => {
  const images = useStaticQuery(graphql`
    query JoinQuery {
      ...signupIllustrations
      ...bountyIllustrations
    }
  `);

  const small = (
    <div className="join-program join-program--small">
      <a href={link}>
        <div className="join-program--small__title">{title}</div>
        <button className="btn-cta">{btnText}</button>
      </a>
    </div>
  );

  const big = (
    <div className="join-program join-program--big">
      <a href={link}>
        <div className="join-program--big__content">
          <div className="join-program--big__title">{title}</div>
          <div className="join-program--big__benefit">{benefit}</div>
        </div>
        <img
          className="join-program--big__image"
          src={images.signupImage.childImageSharp.fixed.src}
          srcSet={images.signupImage.childImageSharp.fixed.srcSet}
          alt={title}
        />
        <button className="btn-cta">{btnText}</button>
      </a>
    </div>
  );

  const bounty = (
    <div className="join-program join-program--bounty">
      <Link to={link}>
        <div className="join-program--big__content">
          <div className="join-program--big__title">{title}</div>
          <div className="join-program--big__benefit">{benefit}</div>
        </div>
        <img
          className="join-program--big__image"
          src={images.bountyImage.childImageSharp.fixed.src}
          srcSet={images.bountyImage.childImageSharp.fixed.srcSet}
          alt={title}
        />
        <button className="btn-cta">{btnText}</button>
      </Link>
    </div>
  );

  switch (variant) {
    case "small":
      return small;
    case "big":
      return big;
    case "bounty":
      return bounty;
    default:
      return small;
  }
};

JoinTheProgram.defaultProps = {
  title: "Join the associates program",
  btnText: "Sign up",
  variant: "small"
};

JoinTheProgram.propTypes = {
  title: PropTypes.string,
  btnText: PropTypes.string,
  link: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["big", "small", "bounty"])
};

export default JoinTheProgram;
