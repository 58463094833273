/**
 * Finds flag in array. Array should come from allFile graphql query
 * @param slug - Slug of the language to search for
 * @param data - Array
 * @return (string) Flag public URL
 */
export function findFlagBySlug(slug, data) {
  const file = data.filter(edge => edge.node.name === slug)[0];

  if (!file) return '';

  return file.node.publicURL;
}
