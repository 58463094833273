import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import JoinTheProgram from "../components/JoinTheProgram";
import {graphql, Link, useStaticQuery} from "gatsby";

const Footer = ({ menu, signUpLink, logo, stringTranslations }) => {

  const data = useStaticQuery(graphql`
      query FooterQuery {
          defaultLogo: file(
              relativeDirectory: { eq: "logos/footer_logos" }
              name: { eq: "en" }
          ) {
              childImageSharp {
                  fixed(height: 35) {
                      src
                      srcSet
                  }
              }
          }
      }
  `);

  const logoImage = logo?.node?.childImageSharp?.fixed;
  const defaultLogo = data.defaultLogo.childImageSharp.fixed;

  return (
    <footer className="page-footer">
      <Container fluid>
        <Row className="page-footer__main">
          <Col xs={12} lg={3}>
            { logoImage ? (
              <img
                src={logoImage.src}
                srcSet={logoImage.srcSet}
                alt="Amazon Associates"
                className="page-footer__logo"
                width={233}
                height={35}
              />
            ) : (
              <img
                src={defaultLogo.src}
                srcSet={defaultLogo.srcSet}
                alt="Amazon Associates"
                className="page-footer__logo"
                width={233}
                height={35}
              />
            )}
          </Col>
          <Col md={5} lg={4}>
            <div className="menu-title">{ stringTranslations["Categories"] }</div>
            <ul className="guides-menu">
              {menu &&
                menu.map(menuItem => (
                  <li key={menuItem.node.cat_name}>
                    <Link to={menuItem.node.path} dangerouslySetInnerHTML={{ __html: menuItem.node.cat_name}} />
                  </li>
                ))}
            </ul>
          </Col>
          <Col
            md={{ span: 6, offset: 1 }}
            lg={{ span: 4, offset: 1 }}
            className="align-items-end"
          >
            <JoinTheProgram
              link={signUpLink}
              title={ stringTranslations["Join the associates programme"] }
              btnText={ stringTranslations["Sign up"] }
            />
          </Col>
        </Row>
        <Row className="page-footer__footnote">
          <div className="page-footer__copyright">
            { stringTranslations["© 1996–2020, Amazon.com, Inc. or its affiliates."] }
          </div>
          <div className="page-footer__legal-section">
            <a
              href="https://www.amazon.co.uk/gp/help/customer/display.html/ref=footer_cou?ie=UTF8&nodeId=1040616"
              target="_blank"
              rel="noreferrer"
            >
              { stringTranslations["Conditions of Use & Sale"] }
            </a>
            <a
              href="https://www.amazon.co.uk/gp/help/customer/display.html/ref=footer_privacy?ie=UTF8&nodeId=502584"
              target="_blank"
              rel="noreferrer"
            >
              { stringTranslations["Privacy Notice"] }
            </a>
            <a
              href="https://www.amazon.co.uk/gp/help/customer/display.html/ref=footer_iba?ie=UTF8&nodeId=201149560"
              target="_blank"
              rel="noreferrer"
            >
              { stringTranslations["Cookies & Internet Advertising"] }
            </a>
          </div>
        </Row>
      </Container>
    </footer>
  );
};

Footer.defaultProps = {
  signUpLink: '#'
}

export default Footer;
